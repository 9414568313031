import React, { useState } from 'react';
import axios from 'axios';
import Whats from "./Whats";
import "../styles/Formulario.scss";
import CircularProgress from '@mui/material/CircularProgress';

const Formulario = () => {
  const phoneContact = 5218116023326;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [formStatus, setFormStatus] = useState(''); // Estado para el mensaje de éxito o error
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = name && phone && email;

  const handleNameChange = (e) => setName(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario

    const formData = {
      name,
      phone,
      email
    };

    setIsLoading(true);
    try {
      if(isFormValid){
        const response = await axios.post('https://vidriart.com/formulario/formulario.php', formData); // Reemplaza con la URL de tu servidor PHP
        setFormStatus('Formulario enviado exitosamente.');
        setIsLoading(false);
  
        // Acceder a los datos de la respuesta
        const responseData = response.data;
        console.log(responseData);
      }else{
        alert("Introduzca los datos del formulario")
      }
     
    } catch (error) {
      setFormStatus('Error al enviar el formulario.');
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="formContainerMain mt-3 p-5">
      <form className="border formContainer p-5" onSubmit={handleSubmit}>
        <div className="text-center">
          <span className="fw-bold fs-4">Contáctanos</span>
        </div>
        <div className="mt-2">
          <input
            type="text"
            id="nombre"
            name="nombre"
            className="form-control inputCustom"
            placeholder="Nombre"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="mt-2">
          <input
            type="number"
            id="telefono"
            name="telefono"
            className="form-control inputCustom"
            placeholder="Telefono"
            value={phone}
            onChange={handlePhoneChange}
          />
        </div>
        <div className="mt-2">
          <input
            type="email"
            id="email"
            name="email"
            className="form-control inputCustom"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="d-flex justify-content-center flex-column gap-2 mt-3 text-center">
          <span className="textTermns">
            Al dar click e ingresar tus datos, estará aceptando las Políticas de Privacidad de la página.
          </span>
          <button className="btn btnAgent" type="submit">
            <i className="fa fa-phone" aria-hidden="true"></i> Envíanos tus datos
          </button>
          <div className='text-center'>
            {isLoading && <CircularProgress sx={{ color: '#FF5733' }} />}
          </div>
          <span>O contáctanos a través de</span>
          <Whats
            classStyle="btn btnWhats"
            message="Hola, quiero más información acerca de sus servicios"
            phoneNumber={phoneContact}
            text="Whatsapp"
          />
        </div>
        {formStatus && <div className="mt-3 text-center">{formStatus}</div>}
      </form>
    </div>
  );
};

export default Formulario;
