import React from "react";
import ImageGallery from "react-image-gallery";
import "../styles/Galery.scss";

const Galery = () => {
  const barandales = [
    {
      original: "https://vidriart.com/galery/barandales/1.jpg",
      thumbnail: "https://vidriart.com/galery/barandales/1.jpg",
    },
    {
      original: "https://vidriart.com/galery/barandales/2.jpg",
      thumbnail: "https://vidriart.com/galery/barandales/2.jpg",
    },
    {
      original: "https://vidriart.com/galery/barandales/3.jpg",
      thumbnail: "https://vidriart.com/galery/barandales/3.jpg",
    },
  ];
  const ventanas = [
    {
      original: "https://vidriart.com/galery/ventanas/1.jpg",
      thumbnail: "https://vidriart.com/galery/ventanas/1.jpg"
    },
    {
      original: "https://vidriart.com/galery/ventanas/2.jpg",
      thumbnail: "https://vidriart.com/galery/ventanas/2.jpg",
    },
    {
      original: "https://vidriart.com/galery/ventanas/3.jpg",
      thumbnail: "https://vidriart.com/galery/ventanas/3.jpg",
    },
    {
      original: "https://vidriart.com/galery/ventanas/4.jpg",
      thumbnail: "https://vidriart.com/galery/ventanas/4.jpg",
    },
  ];
  const canceles = [
    {
      original: "https://vidriart.com/galery/canceles/1.jpg",
      thumbnail: "https://vidriart.com/galery/canceles/1.jpg",
    },
    {
      original: "https://vidriart.com/galery/canceles/2.jpg",
      thumbnail: "https://vidriart.com/galery/canceles/2.jpg",
    },
    {
      original: "https://vidriart.com/galery/canceles/3.jpg",
      thumbnail: "https://vidriart.com/galery/canceles/3.jpg",
    },
    {
      original: "https://vidriart.com/galery/canceles/4.jpg",
      thumbnail: "https://vidriart.com/galery/canceles/4.jpg",
    },
    {
      original: "https://vidriart.com/galery/canceles/5.jpg",
      thumbnail: "https://vidriart.com/galery/canceles/5.jpg",
    },
    
  ];
  const puertas = [
    {
      original: "https://vidriart.com/galery/puertas/1.jpg",
      thumbnail: "https://vidriart.com/galery/puertas/1.jpg",
    },
    {
      original: "https://vidriart.com/galery/puertas/2.jpg",
      thumbnail: "https://vidriart.com/galery/puertas/2.jpg",
    },
    {
      original: "https://vidriart.com/galery/puertas/3.jpg",
      thumbnail: "https://vidriart.com/galery/puertas/3.jpg",
    },
    {
      original: "https://vidriart.com/galery/puertas/4.jpg",
      thumbnail: "https://vidriart.com/galery/puertas/4.jpg",
    },
    {
      original: "https://vidriart.com/galery/puertas/5.jpg",
      thumbnail: "https://vidriart.com/galery/puertas/5.jpg",
    },
  ];
  const proyectos = [
    {
      original: "https://vidriart.com/galery/proyectos/1.jpg",
      thumbnail: "https://vidriart.com/galery/proyectos/1.jpg",
    },
    {
      original: "https://vidriart.com/galery/proyectos/2.jpg",
      thumbnail: "https://vidriart.com/galery/proyectos/2.jpg",
    },
    {
      original: "https://vidriart.com/galery/proyectos/3.jpg",
      thumbnail: "https://vidriart.com/galery/proyectos/3.jpg",
    },
    {
      original: "https://vidriart.com/galery/proyectos/4.jpg",
      thumbnail: "https://vidriart.com/galery/proyectos/4.jpg",
    },
  ];

  return (
    <div className="p-3 text-center">
      <h3 className="mt-3 mb-3 fw-bold">Galería de Imágenes</h3>
      <ImageGallery items={barandales} />
      <hr />
      <ImageGallery items={ventanas} />
      <hr />
      <ImageGallery items={canceles} />
      <hr />
      <ImageGallery items={puertas} />
      <hr />
      <ImageGallery items={proyectos} />
    </div>
  );
};

export default Galery;
